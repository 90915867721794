import React from 'react';
import './Products.css';
import img1 from '../../Assests/erp1.jpeg'

const Products = () => {
  return (
    <>
    <div className='p-banner'>
      <h1>Our Products</h1>
    </div>
     <div>
        <br/>
        <br/><br/>
        <br/>
            <div className='p1-all'>
            <div className='p-about'>
                <h2 >OURSERP</h2>
                <p>
                   Ourserp designed for Small and Medium Enterprises looking
                   for a cost effective solution which can enable them to quickly move away from current manual processes to a fully integrated business solution.
                   <br/>
                   we've designed our entire work process providing everything a national/international business needs while starting and ensuring that working with us is always a quick, easy and hassle-free experience.
                   <br/>
                   we have a team of 15 Year plus experience for Engineering,manufacturing,Job Work and Distribution Industry into Custom Application development and implementation based on like -
                    <ul>
                        <li>Offline(LAN based)</li>
                        <li>Cloud Based (Complete Online)</li>
                        <li>Hybrid tech(Online/Offline)</li>
                        <li>Virtual Machine(Online Cloud based)</li>
                    </ul>
  
                </p>
            </div>
            <div className='p-image'>
                <img src={img1}/>
            </div>
         </div>
    </div>
    </>
   
  )
}

export default Products