import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import aboutImg from "../Assests/about-img.png";
import career from "../Assests/portfolio/career.png";
import mkh from "../Assests/portfolio/mkh.png";
import iglife from "../Assests/portfolio/iglife.png";
import ve from "../Assests/portfolio/ve.png";
import lb from "../Assests/portfolio/lb.png";
import gi from "../Assests/portfolio/gi.png";
import ki from "../Assests/portfolio/ki.png";
import mndc from "../Assests/portfolio/mndc.png";
import gentop from "../Assests/portfolio/gentop.png";
import winner from "../Assests/portfolio/winner.png";
import nawal from "../Assests/portfolio/nawal.png";
import braj from "../Assests/portfolio/braj.png";
import letc from "../Assests/portfolio/letc.png"
import universal from "../Assests/portfolio/universal.png"
import pandit from "../Assests/portfolio/pandit.png";
import kk from "../Assests/portfolio/kk.png";
import vasu from "../Assests/portfolio/vasundara.png";
import kota from "../Assests/portfolio/kota.png";
import samadhan from '../../src/Assests/portfolio/samadhan.png'
import spark from '../../src/Assests/portfolio/spark.png'
import nexinfra from '../../src/Assests/portfolio/nexinfra.png'
import ashtronix from '../../src/Assests/portfolio/ashtronix.png'


const projects = [
  {
    id: "letc",
    link: "https://letceducation.in",
    img: letc,
    title: "Letc Education",
  },
  {
    id: "universalfire",
    link: "https://universalfiresecurity.com",
    img: universal,
    title: "Universal Fire Security",
  },
  
  {
    id: "kkfinance",
    link: "https://kkfinancialserve.in",
    img: kk,
    title: " kk financial services",
  },
  {
    id: "vasundara",
    link: "https://vasundaraconstructions.in",
    img: vasu,
    title: "Vasundara Constructions",
  },
  {
    id: "Kotaguru",
    link: "https://kotaguru.com",
    img: kota,
    title: "Kota Guru",
  },
  {
    id: "Samadhan Academy",
    link: "https://www.samadhanacademy.in",
    img: samadhan,
    title: "Samadhan Academy",
  },
  {
    id: "Spark Digital India",
    link: "https://www.sparkdigitalindia.com",
    img: spark,
    title: "Spark Digital India",
  },
  {
    id: "Nexinfra",
    link: "https://www.nexinfras.com",
    img: nexinfra,
    title: "Nexinfra",
  },
  
  {
    id: "Ashtronix Solutions",
    link: "https://www.ashtronixsolutions.com",
    img: ashtronix,
    title: " Ashtronix Solutions",
  },
  {
    id: "career",
    link: "https://careeruttsav.com",
    img: career,
    title: "Career Uttsav",
  },
  {
    id: "shri",
    link: "https://mkhastrovastu1.com",
    img: mkh,
    title: "MKH Astrovastu 1",
  },
  {
    id: "panditpuja",
    link: "https://panditpuja.in",
    img: pandit,
    title: "Pandit Puja",
  },
  {
    id: "iglife",
    link: "https://miglife.in",
    img: iglife,
    title: "MIGLIFE",
  },
  {
    id: "vishwakarma",
    link: "https://vishwakarmaenterprises.in",
    img: ve,
    title: "Vishwakarma Enterprises",
  },
  {
    id: "life",
    link: "https://lifebeginsindia.in",
    img: lb,
    title: "Life Begins",
  },
  {
    id: "gi",
    link: "https://globalinteriorsindia.com",
    img: gi,
    title: "Global Interiors",
  },
  {
    id: "ki",
    link: "http://kalakaarinteriors.com",
    img: ki,
    title: "Kalakaar Interiors",
  },
  {
    id: "mndc",
    link: "http://mndcbangalore.org",
    img: mndc,
    title: "MNDC Bangalore",
  },
  {
    id: "gentop",
    link: "https://gentop.in",
    img: gentop,
    title: "Gentop India Pvt Ltd",
  },
 
  {
    id: "nawal",
    link: "https://nawalagro.com",
    img: nawal,
    title: "Nawal Agro",
  },
  {
    id: "braj",
    link: "https://brajgupta.com",
    img: braj,
    title: "Braj Gupta",
  },
];

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <div className="container-lg py-5">
      <div className='p-banner'>
      <h1>Our Portfolio</h1>
    </div>
        
        <div className="row d-flex justify-content-center pt-5">
          {projects.map((project) => {
            return (
              <div
                className="col-10 col-md-3 mx-4 mb-3 pb-3 pt-4 text-center shadow rounded-4"
                key={project.id}
              >
                <Link className="text-decoration-none" to={project.link}>
                  <div className="project">
                    <img
                      height={150}
                      width="100%"
                      src={project.img}
                      alt="..."
                      className="mb-3 rounded-3"
                    />
                    <h4 className="text-dark fw-bold">{project.title}</h4>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
